import { Link } from '@remix-run/react'
import clsx from 'clsx'
import React from 'react'
import { rem } from '~/utils'

const baseStyles = 'inline-flex items-center justify-center rounded ' +
  'hover:bg-slate-100/40 hover:translate-y-[-1px] ' +
  'active:bg-slate-400/30 active:translate-y-px'

interface CommonProps {
  size?: number
}
type ButtonProps = React.ComponentProps<'button'> & CommonProps
type LinkProps = React.ComponentProps<typeof Link> & CommonProps
type Props = ButtonProps | LinkProps

const ClearIconButton = React.forwardRef<HTMLElement, Props>((
  { children, className, ...props },
  ref,
) => {
  let Component: 'button' | typeof Link = 'button'
  if ('to' in props) {
    Component = Link
  }

  const sizeStyle = rem(props.size ?? 24)
  const style = {
    width: sizeStyle,
    height: sizeStyle,
  }

  return (
    <Component
      {...props as any}
      ref={ref as any}
      className={clsx(baseStyles, className)}
      style={style}
    >
      {children}
    </Component>
  )
})

ClearIconButton.displayName = 'ClearIconButton'

export default ClearIconButton
